#map {
	width: 100%;
	height: 800px;
}

#map {
	width: 100%;
	height: 800px;
}

.App .section {
	margin-top: 20px;
}

.App .section .date-slider {
	position: relative;
	width: calc(100% - 370px);
	display: inline-block;
	vertical-align: top;
}

.App .section .filters {
	width: 200px;
	display: inline-block;
	vertical-align: top;
}

.App .about {
	margin: 0 auto;
	color: white;
	padding: 48px 18px;
	line-height: 28px;
}

.App .about .title {
	font-weight: bold;
}

.App .about a {
	color: #46b5c1;
}

@media (min-width: 640px) {
	.App .about {
		width: 580px;
	}
}

@media (min-width: 768px) {
	.App .about {
		width: 700px;
	}
}

@media (min-width: 1280px) {
	.App .about {
		width: 1000px;
	}
}

body {
	margin: 0;
	padding: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #292c32;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

